import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { Menu, Image } from 'semantic-ui-react'
import MenuBtn from './MenuBtn';
import './Nav.css';
import NavModal from './NavModal';


const Nav = ({ showMenuBtn, showMenu, setMenuVis }) => {
  const { images:{ njspotlight_logo, njtv_logo }} = useContext(ThemeContext)
  return (
    <React.Fragment>
      <Menu inverted widths={6} borderless className='nav' secondary compact fixed='top'>
        <Menu.Item as='a' href='https://www.njspotlight.com' target="_blank" className='spotlight-logo' rel="noopener noreferrer" >
            <Image src={njspotlight_logo}  size='small' className='blog-logo' />
        </Menu.Item>
        <Menu.Item>
        <div style={{position: 'relative'}}>
          <MenuBtn openMenuFn={setMenuVis} pose={showMenuBtn ? 'show' : 'hide'} isOpen={showMenu}/>
          <NavModal isVisible={showMenu} closeFn={()=>{setMenuVis(!showMenu)}} />
        </div>
        </Menu.Item>
        <Menu.Item as='a' href='https://www.njtvonline.org/news' target="_blank" className='njtv-logo' rel="noopener noreferrer" >
            <Image src={njtv_logo} size='small' className='blog-logo'/>
        </Menu.Item>
      </Menu>
    </React.Fragment>
  )
}

export default Nav;

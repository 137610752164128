import React, { useContext } from 'react';
import { ThemeContext } from '../ThemeContext';

export const VideoEle = ({ video_src }) => {
    return (
        <video autoPlay muted loop id="myVideo">
            <source src={video_src} type="video/mp4" />
        </video>
    )
}

const isIMG = src => /.*\.jpg$/.test(src)

export const checkMedia = (filename) => {
    if (!isIMG(filename)){
        return <VideoEle video_src={filename} />
      } else if (isIMG(filename)){
        return filename
      } else {
        return null;
      }
}

export const useMedia = section_inx => {
  const { section_banners, vid_src } = useContext(ThemeContext)

  const filename = vid_src('headers/' + section_banners[section_inx]);

  if (!isIMG(filename)){
    return {
        children: checkMedia(filename),
        amount: 0.4
    }
  } else if (isIMG(filename)){
    return {
        image: checkMedia(filename)
    }
  } else {
    return {};
  }
}

import React from 'react';
import styles from './styles'

import img_placeholder from './images/image-placeholder.jpg'

import njtv_logo from './images/BannerLogo2_NJTVNews.svg'
import njspotlight_logo from './images/BannerLogo1_NJSpotlight.svg'
import farmland_logo from './images/NJFarmingFlourishes_logo.png'
import farmland_menu_logo from './images/FarmScene-menu.png'
import farm_logo_btn from './images/FarmlandScene-menuicon_OnState.png'
import farm_logo_off from './images/FarmlandScene-menuicon_OffState.png'


let ThemeContext;

const { Provider, Consumer } = ThemeContext = React.createContext();

const images = {
    njtv_logo,
    njspotlight_logo,
    farmland_logo,
    farmland_menu_logo,
    farm_logo_btn,
    farm_logo_off
}

const API_URL = 'https://njtv-online-media-assets.s3.amazonaws.com/farmland/'

const isExternal = src => /http(s)?:\/\//.test(src)

const img_src = src => src ? isExternal(src) ? src : `${API_URL}images/${src}`: img_placeholder
const vid_src = src => `${API_URL}${src}`

const sections = [
    {
        id: 'section_1',
        title: 'New Jersey’s Farmland Revival'
    },
    {
        id: 'section_2',
        title: 'The State Steps In'
    },
    {
        id: 'section_3',
        title: 'Preserve and Protect'
    },
    {
        id: 'section_4',
        title: 'More Steps to Save the Soil'
    },
    {
        id: 'section_5',
        title: 'Attracting Agritourists'
    },
    {
        id: 'section_6',
        title: 'Working Dawn to Dusk'
    },
    {
        id: 'section_7',
        title: 'Jersey’s Bountiful Harvest'
    },
    {
        id: 'section_8',
        title: 'Aging Farmers'
    },
    {
        id: 'section_9',
        title: 'The New Generation'
    }
]

const bkgrn_videos = {
    section_2: vid_src('headers/StateStepsIn.mp4'),
    section_3: vid_src('headers/PreserveAndProtect_LOOP.mp4'),
    section_4: vid_src('headers/StepsToSaveTheSoil.mp4'),
    section_8: vid_src('headers/AgingFarmers.mp4'),
    section_5: vid_src('headers/Agritourism_LOOP.mp4'),
    section_7: vid_src('headers/BountifulHarvest.jpg'),
    section_6: vid_src('headers/DawnToDusk.jpg'),
    section_9: vid_src('headers/NextGeneration.jpg')
}

const section_banners = {
    section_2: 'StateStepsIn.mp4',
    section_3: 'PreserveAndProtect_LOOP.mp4',
    section_4: 'StepsToSaveTheSoil.mp4',
    section_5: 'Agritourism_LOOP.mp4',
    section_6: 'DawnToDusk.jpg',
    section_7: 'BountifulHarvest.jpg',
    section_8: 'AgingFarmers.mp4',
    section_9: 'NextGeneration.jpg',
}

const ThemeProvider = ({ children }) => {
    return(
        <Provider
            value={{
                images,
                sections,
                section_banners,
                api_url: API_URL,
                styles,
                img_src,
                vid_src,
                bkgrn_videos
            }}>
            { children }
        </Provider>
    )
}

export { ThemeContext, Consumer as ThemeConsumer, ThemeProvider };

import React, { useContext, forwardRef } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { Image, Button } from 'semantic-ui-react'
import posed from 'react-pose';
import './Nav.css'

import { gaEvent } from '../../lib/ga_utils'


const MyMenuBtn = ({ openMenuFn, isOpen }) => {
    const { images:{ farmland_menu_logo }} = useContext(ThemeContext)
    return (
        <Button onClick={()=>{
            gaEvent('Nav', 'Nav Menu', 'open');
            openMenuFn(!isOpen);
        }} className='menu-btn'>
            <Image src={farmland_menu_logo} alt='' size='small'/>
        </Button>
    )
}


const MenuBtn = forwardRef((props, ref) =>(
    <div ref={ref}>
        <MyMenuBtn {...props} />
    </div>
))

const PosedMenuBtn = posed(MenuBtn)({
    hoverable:true,
    init:{
        y: 0
    },
    hover:{
        backgroundColor: '#aaa'
    },
    show:{
        opacity: 1,
        y: 0,
        scale: 1,
        transition: { duration: 300 }
    },
    hide:{
        y: 50,
        scale: 0.5,
        opacity: 0,
        transition: { duration: 300 }
    },
    initialPose: 'hide'
})

export default PosedMenuBtn;

import React from 'react';
import data from './chart_data'

let ChartDataContext;
const { Provider, Consumer } = ChartDataContext = React.createContext();


const ChartDataProvider = ({ chart_name, children }) => {
    return(
        <Provider
            value={{
                chart_data: data[chart_name]
            }}>
            { children }
        </Provider>
    )
}

export { ChartDataContext, Consumer as ChartDataConsumer, ChartDataProvider };

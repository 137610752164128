import React, { useEffect, useState, lazy, Suspense } from 'react';
import ErrorBoundary from '../ErrorBoundary'

const DynamicModule = ({ component, placeholder, ...rest }) => {
    const [Component, setComponent] = useState(null);
    // const [initializing, setInitalizing] = useState(false);

    // const onInitialized = () => {
    //     setInitalizing(false)
    // }

    useEffect(()=>{
        setComponent(lazy(component));
        // setInitalizing(true)

        return ()=>{

        }
    },[component])
  return (
    <ErrorBoundary fallback={<div>Error</div>}>
        <Suspense fallback={placeholder}>
            {Component && <Component {...rest}  />}
        </Suspense>
    </ErrorBoundary>
  )
}

export default DynamicModule;

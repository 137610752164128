import React, { useContext } from 'react';
import { ChartDataProvider, ChartDataContext } from './ChartDataContext';

import BarChart from './BarChart'
import AreaChart from './AreaChart'

const ChartContainer = ({ margin }) => {
    const  { chart_data } = useContext(ChartDataContext)
    if (chart_data.type && chart_data.type === 'bar'){
        return <BarChart {...chart_data} margin={margin} />
    }
    return (
        <AreaChart {...chart_data} margin={margin} />
    )
}


const Chart = ({ chart, margin }) => {
    return (
        <ChartDataProvider chart_name={chart}>
            <ChartContainer margin={margin} />
        </ChartDataProvider>
    )
}

export default Chart;

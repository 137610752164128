const farm_land = {
  data: [
    {name: '1974', land: 961395 },
    {name: '1978', land: 1049435 },
    {name: '1982', land: 916331 },
    {name: '1987', land: 894426 },
    {name: '1992', land: 847595 },
    {name: '1997', land: 832600 },
    {name: '2002', land: 805682 },
    {name: '2007', land: 733450 },
    {name: '2012', land: 715057 },
    {name: '2017', land: 734084 }
  ],
  keys: ['land'],
  labels: ['Farm acres'],
  height: 170,
  showLegend: false,
  header: 'Total farm acreage',
  source: 'USDA 2017 Census of Agriculture'
};

const animals = {
  data: [
    { name: '1974', cattle: 106899, hogs: 63193},
    { name: '1982', cattle: 94999, hogs: 53767},
    { name: '1987', cattle: 77581, hogs: 31968},
    { name: '1992', cattle: 69134, hogs: 29645},
    { name: '1997', cattle: 58483, hogs: 25390},
    { name: '2002', cattle: 41747, hogs: 14162},
    { name: '2007', cattle: 38198, hogs: 8551},
    { name: '2012', cattle: 31449, hogs: 7901},
    { name: '2017', cattle: 27789, hogs: 9017},
  ],
  keys: ['cattle', 'hogs'],
  labels: ['Cattle and calves', 'Hogs and pigs '],
    height: 200,
  header: 'Number of farm animals',
  source: 'USDA 2017 Census of Agriculture'
}
const min_wage = {
  data: [
    { name: '1/19', most: 8.85, seasonal: 8.85, agriculture: 8.85},
    { name: '7/19', most: 10.00, seasonal: 8.85, agriculture: 8.85},
    { name: '1/20', most: 11.00, seasonal: 10.30, agriculture: 10.30},
    { name: '1/21', most: 12.00, seasonal: 11.10, agriculture: 10.30},
    { name: '1/22', most: 13.00, seasonal: 11.90, agriculture: 10.90},
    { name: '1/23', most: 14.00, seasonal: 12.70, agriculture: 11.70},
    { name: '1/24', most: 15.00, seasonal: 13.50, agriculture: 12.50}
  ],
  type: 'bar',
  keys: ['most', 'seasonal', 'agriculture'],
  header: 'Increase in NJ Minimum Wage',
  labels: ['Most Workers', 'Seasonal workers and employees of small businesses', 'Agriculture workers'],
  source: 'NJ Department of Labor and Workforce Development'
}



export default {
  farm_land,
  animals,
  min_wage
}

import React from 'react';
import './Credits.css';
import credits from './credits_data';


const BylineComp = (props) => {
  return (
        <ul
            className='byline'
        >
            {credits.map((c,i)=>(
                <li
                    className='byline-item'
                    key={i}
                >
                    {c.name}, <small>{c.title}</small>
                </li>
            ))}
        </ul>
  )
}

export default BylineComp;

import React from 'react';
import posed from 'react-pose';
import './Nav.css';

const PosedShade = posed.div({
    open: {
        opactiy: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        transition:{
            default: { duration: 300 }
        }
    },
    closed:{
        opactiy: 0,
        backgroundColor: 'rgba(0, 0, 0, 0)',
        transition:{
            default: { duration: 300 }
        }
    }
})

const Shade = (props) => {
  return (
    <PosedShade className='shade' {...props}/>
  )
}

export default Shade;

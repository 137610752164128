import ReactGA from 'react-ga';

const DEV_ENV = process.env.NODE_ENV === 'development'

const slugify = text =>
    text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w-]+/g, '')       // Remove all non-word chars
        .replace(/--+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');


const gaEvent = (cat, action, label) => {
    if (!DEV_ENV){
        ReactGA.event({
          category: cat,
          action: action,
          label: label
        });
    }
}

const gaPageView = (title) => {
    if (title && !DEV_ENV){
        ReactGA.pageview(`/${slugify(title)}`,[], title);
    }
}

export {
    gaEvent,
    gaPageView
}

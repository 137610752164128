import React from 'react';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Header } from 'semantic-ui-react'
import "../Charts.css"
import CustomTooltip  from '../CustomTooltip';

const colors = ["#82ca9d", "#8884d8", "#ffc658"]

const AreaChartComponent = ({ data, keys=['value'], source, labels=[], header, showLegend=true, margin, height=300}) => {
    return (
      <div >
          { !!header && <Header as='h3' textAlign='center'>{header}</Header>}
        <ResponsiveContainer height={height}>
          <AreaChart
            data={data}
            margin={margin}
            className='chart'
          >
            <defs>
              <linearGradient id="color0" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
              </linearGradient>
              <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8}/>
                <stop offset="95%" stopColor="#ffc658" stopOpacity={0}/>
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3"/>
            <XAxis dataKey="name" />
            <YAxis type='number' tickFormatter={(tick)=>tick.toLocaleString()}/>
            <Tooltip content={<CustomTooltip />} />
            {showLegend && <Legend />}
            {keys.map((k,i)=>(
              <Area key={i} name={labels[i] ? labels[i] : keys[i]} type="monotone" dataKey={k} stroke={colors[i]} fillOpacity={1} fill={`url(#color${i})`} />
            ))}
          </AreaChart>
        </ResponsiveContainer>
        { !!source && <small>Source: <em>{source}</em></small>}
      </div>
    );
}


export default AreaChartComponent

const themeColors = {
    color_1: '#001970',
    color_2: '#303f9f',
    color_3: '#666ad1',
    color_4: '#ff8a50',
    color_5: '#ff5722',
    color_6: '#c41c00',
    color_7: 'green',
    color_8: 'green',
}

const textColors = {
    main: '#525252',
    muted: '#525252',
    strong: '#525252'
}


const themeSettings = {
    lineSpacing: {
        lineHeight: '1.2em'
    },
    textCenter: {
         textAlign: 'center',
    },
    colors: {
        section_1:{
            primary: themeColors.color_1
        },
        section_2:{
            primary: themeColors.color_2
        },
        section_3:{
            primary: themeColors.color_3
        },
        section_4:{
            primary: themeColors.color_4
        },
        section_5:{
            primary: themeColors.color_5
        },
        section_6:{
            primary: themeColors.color_6
        },
        section_7:{
            primary: themeColors.color_7
        },
        section_8:{
            primary: themeColors.color_8
        }
    }

}

const styles = {};

styles.caption = {
    ...themeSettings.textCenter,
    fontStyle: 'italic'
}


styles.header = {
    ...themeSettings.lineSpacing
}

styles.title = {
    ...themeSettings.lineSpacing,
    ...themeSettings.textCenter,
    color: textColors.main,
    fontWeight: 'bold',
    textTransform : 'uppercase',
}

styles.subtitle = {
    ...themeSettings.lineSpacing,
    color: textColors.muted,
}

styles.credits = {
    container:{
        position: 'absolute',
        right: 10,
        top: 10,
        backgroundColor: 'white',
        color: 'gray',
        display: 'block',
        zIndex: 999,
        textAlign: 'right',
        padding: 10,
        cursor: 'pointer',
        borderRadius: 10,
    },
    content: {
        ...themeSettings.lineSpacing,
    },
    button: {
        ...themeSettings.textCenter
    }
}

styles.image = {
    wrapper: {
        ...styles.block
    },
    img:{
        ...styles.block,
        borderRadius: 10,
        width: '100%'
    },
    caption: {
        ...themeSettings.textCenter,
        fontStyle: 'italic'
    }
}

styles.sectionHeader = {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    ...themeSettings.textCenter,
}
styles.flourish_img = {
    width: 45,
    position: 'relative',
    top: 5
}
styles.flourish = {
    right: {
        ...styles.flourish_img,
        transform: 'scaleX(-1)',
        filter: 'FlipH'
    },
    left: {
        ...styles.flourish_img,
        display: 'inline-block'
    }
}
styles.block = {
    display: 'block'
}
styles.img = {
    ...styles.block,
    borderRadius: 10
}
styles.img_block = {
    ...styles.block,
    border: 'thin solid #ccc5c5',
    backgroundColor: '#eaeaea',
    padding: 10,
    borderRadius: 10,
}

styles.float = {
    left: {
        marginRight: 10,
        float: 'left'
    },
    right: {
        marginLeft: 10,
        float: 'right'
    },
    block: {
        float: 'none',
        clear: 'both',
        width: '100%'
    }
}

styles.size = {
    large: {
        width: '80%'
    },
    big: {
        width: '65%'
    },
    medium: {
        width: '40%'
    },
    small: {
        width: '40%'
    },
    mini: {
        width: '25%'
    }
}

export default styles;

import React, { useContext, useState } from 'react';
import { ThemeContext } from '../../ThemeContext';
import posed from 'react-pose';
import './ImageElement.css';
import { gaEvent } from '../../lib/ga_utils'
import { tween } from 'popmotion'

const PosedModal = posed.div({
    fullscreen:{
        width: '100%',
        cursor: 'zoom-out',
        transistion: tween,
        boxShadow: '5px 5px 10px rgba(0,0,0, 0.3)',
        flip: true
    },
    thumbnail:{
        width: '300px',
        cursor: 'zoom-in',
        boxShadow: '0px 0px 0px rgba(0,0,0,0)',
        transistion: tween,
        flip: true
    }
})

const ImageEle = posed.img({
    fullscreen:{
        cursor: 'zoom-out',
        transistion: tween,
        flip: true
    },
    thumbnail:{
        cursor: 'zoom-in',
        transistion: tween,
        flip: true
    }
})

const ImageElement = ({ src, className, style }) => {
    const { img_src } = useContext(ThemeContext);
    const img = img_src(src);
    const [ isFullScreen, setFullScreen ] = useState(false)
    if(!src) return null;
    return (
        <PosedModal
            pose={isFullScreen ? 'fullscreen' : 'thumbnail'}
            onClick={()=>{
                gaEvent('Image', (!isFullScreen ? `View image` : `Close image`), src);
                setFullScreen(!isFullScreen);
            }}
            className={`image-wrapper ${className ? className : ''}`}
            style={style}
        >
            <ImageEle src={img} alt="" key="image-ele" className='image-ele' />
        </PosedModal>
    )
}


export default ImageElement;

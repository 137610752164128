import casey_img from '../../images/headshots/casey_burden.jpg';
import adam_img from '../../images/headshots/adam_cutrone.jpg';
import colleen_img from '../../images/headshots/colleen_odea.jpg';
import lindsay_img from '../../images/headshots/lindsay_rassmann.jpg';
import vinny_img from '../../images/headshots/vinny_caravano.jpg';
import ian_img from '../../images/headshots/ian_elliott.jpg';

const credits = [
    {
        name: "Colleen O'Dea",
        img: colleen_img,
        title: 'General Assignment Reporter',
        bio: 'is a general assignment reporter specializing in data journalism with NJ Spotlight.'

    },
    {
        name: 'Lindsay Rassmann',
        img: lindsay_img,
        title: 'Multimedia Producer',
        bio: 'is an associate multimedia producer with NJTV News. She contributed to the filming and editing.'

    },
     {
        name: 'Casey Burden',
        img: casey_img,
        title: 'Digital Product Specialist',
        bio: 'is a digital product specialist at NJTV News. He developed and wrote the code for the site.'

    },
     {
        name: 'Vinny Caravano',
        img: vinny_img,
        title: 'Art Director',
        bio: 'is the art director for NJTV News.'

    },
     {
        name: 'Adam Cutrone',
        img: adam_img,
        title: 'Graphic Designer',
        bio: 'is a graphic designer at NJTV News'
    },
     {
        name: 'Ian Elliott',
        img: ian_img,
        title: 'Technical Manager',
        bio: 'is the technical manager at NJTV News. He was the lead camera operator for this project.'
    },
]

export default credits

import React, { useContext } from 'react';
import { gaEvent } from '../../lib/ga_utils';
import { ThemeContext } from '../../ThemeContext';
import { Visibility } from 'semantic-ui-react'
import { Image, Icon, Header, Segment } from 'semantic-ui-react'
import { Byline } from '../Credits'
import YouTubeVideo from '../YouTubeVideo';
import posed from 'react-pose';
import './BlogHeader.css'

const ToCBtn = posed.button({
  pressable: true,
  hoverable: true,
  init:{
    scale: 1,
    opacity: 1,
    y: 0,
    boxShadow: '0 0 0 rgba(0,0,0,0)'
  },
  press:{
    scale: 1.08
  },
  hover:{
    scale: 1.05,
    boxShadow: '5px 5px 5px rgba(0,0,0,0.2)'
  },
  visible:{
    opacity: 1,
    y: 0,
    transistion:{
      delay: 400,
      duration: 300
    }
  },
  hidden:{
    opacity: 0,
    y: 200,
    transistion:{
      duration: 300
    }
  }
})


const BlogHeader = ({ topPassed, showMenu, setMenuVis }) => {
  const { images:{ farmland_logo }} = useContext(ThemeContext)

  return (
    <div className="blog-header">
        <div className='blog-header-content'>
          <Visibility
            continuous={true}
            onTopPassed={topPassed}
            onTopPassedReverse={topPassed}
          >
            <Image src={farmland_logo} size='huge' centered className='logo' />
          </Visibility>
          <Segment basic>
            <Header as='h3' inverted className='blog-header-text'>
              A special report by NJ Spotlight and NJTV News<br /> on farming in New Jersey<br /><small style={{color:'#aaa'}}>published Aug 13, 2019</small>
            </Header>
            <Byline />
            <ToCBtn
              onClick={()=>{
                setMenuVis(!showMenu);
                gaEvent('Nav', 'TOC Button', 'open');
              }}
              icon='angle double down'
              className='toc-btn btn'
              pose={!showMenu ? 'visible' : 'hidden'}
            >
              Table of Contents
            </ToCBtn>
          </Segment>
            <Icon className='downArrow bounce' name='angle double down' size='big'/>
          <YouTubeVideo videoId='yBFi3S9qJy4' />
        </div>
    </div>
  )
}

export default BlogHeader;

import React from 'react';
import './YouTubeVideo.css';

const YouTubeVideo = ({ videoId }) => {
  return (
    <div
      className="youtube-video-wrapper"
    >
      <iframe
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        className='youtube-video-element'
        title='YouTube Video'
        src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&rel=0&showinfo=0`}
        frameBorder="0"
      />
    </div>
  )
}

export default YouTubeVideo;

import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import { Image } from 'semantic-ui-react'
import posed from 'react-pose';
import Shade from './Shade';
import NavItem from './NavItem';
import { gaEvent } from '../../lib/ga_utils'

import './Nav.css';

const ModalContainer = posed.div({
    open:{
        delayChildren: 200,
        staggerChildren: 50,
    },
    closed:{
        delayChildren: 200,
        staggerChildren: 50,
    }
})

const PosedTopModal = posed.ul({
    open:{
        y:0,
        opactiy: 1,
        delayChildren: 50,
        staggerChildren: 50,
        transition:{
            default: { duration: 200 }
        }
    },
    closed:{
        y: '-110%',
        delay: 50,
        opactiy: 0,
        transition: { duration: 200 }
    },
    initialPose: 'closed'
})

const CloseBtn = posed.button({
    hoverable: true,
    init:{
        scale: 1,
        color: '#000'
    },
    hover:{
        color: '#fff',
        scale: 1.02
    }
})

const NavModal = ({ isVisible, closeFn }) => {
    const { images:{ farmland_logo }, sections } = useContext(ThemeContext)

  return (
    <ModalContainer className='modal-container' pose={isVisible ? 'open' : 'closed'}>
        <PosedTopModal key='modal' className='modal'>
            <Image src={farmland_logo} size='medium' centered/>
            {sections.map(({ id, title }, inx)=>(
                <NavItem key={id} id={id} inx={inx} title={title} closeFn={closeFn}/>
            ))}
            <CloseBtn className='close-btn' onClick={()=>{
                closeFn();
                gaEvent('Nav', 'Click close', 'button')
            }}>CLOSE</CloseBtn>
        </PosedTopModal>
        {isVisible && <Shade key='shade' onClick={()=>{
                closeFn();
                gaEvent('Nav', 'Click close', 'overlay')
            }}/>}
    </ModalContainer>
  )
}

export default NavModal;

import React, { useState } from 'react';
import './App.css';
import { Grid, Container } from 'semantic-ui-react'
import BackgroundVideo from './components/BackgroundVideo';
import BlogHeader from './components/BlogHeader';
import Sections from './sections/Section_1';
import Footer from './components/Footer'

import Nav from './components/Nav';

function App() {

  const [ showMenuBtn, setMenuBtnVis ] = useState(false);
  const [ showMenu, setMenuVis ] = useState(false);
  const topPassed = (_, { calculations:{ topPassed }}) => { setMenuBtnVis(topPassed) }

  return (
      <Container fluid style={{marginLeft:'none', marginRight:'none'}}>
        <Nav showMenuBtn={showMenuBtn} showMenu={showMenu} setMenuVis={setMenuVis} />
        <BackgroundVideo src='DroneTitleVideo.mp4'/>
        <BlogHeader topPassed={topPassed} showMenu={showMenu} setMenuVis={setMenuVis}/>
        <Grid centered className='section-container'>
            <Sections />
        </Grid>
        <Footer />
      </Container>
  );
}


export default App;

import React from 'react';
import './Footer.css'
import Credits from '../Credits';
import { Grid } from 'semantic-ui-react'

const Footer = (props) => {
  return (
        <Grid centered container className='footer'>
            <Grid.Column mobile={16} computer={15}>
                <Credits />
            </Grid.Column>
        </Grid>
  )
}

export default Footer;

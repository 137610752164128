import { useState, useReducer, useEffect } from 'react';


const useVideoPlayer = () => {

    const [videoState, setState] = useReducer(
        (state, newState)=>({...state, ...newState}),
        {
            isPlaying: undefined,
            isMuted: false,
            progress: 0,
            currentTime: 0,
            duration: 0,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
        })

    const [player, setPlayer] = useState()

    const handleResize = () => {
        setState({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        })
    }
    const handleTimeUpdate = (currentTime, progress, duration) => {
        setState({ progress, currentTime, duration })
    }
    useEffect(()=>{
        if (player){
              setState({
                isPlaying: !player.isPaused,
                isMuted: player.isMuted,
              })
        }
        window.addEventListener('resize', handleResize);
    }, [player])

    const playVideo = () => {
        player.togglePlay()
    }

    const muteVideo = () => {
        player.toggleMute()
    }

    return [ videoState, setState, setPlayer, handleTimeUpdate, playVideo, muteVideo ]
}

export default useVideoPlayer

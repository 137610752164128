import React from 'react';
import { Grid, Segment, Header } from 'semantic-ui-react'
import YouTubeVideo from '../components/YouTubeVideo';
import ImageElement from '../components/ImageElement';
import DynamicModule from '../components/common/DynamicModule'
import Section from './Section'
import Chart from '../charts'

const SectionOne = () => {
  return (
        <React.Fragment>
            <Section
                title='New Jersey’s Farmland Revival'
                id="section_1"
                showBanner={false}
                color='#001970'>
                <Grid container centered>
                    <Grid.Column>
                        <Header as='h2' textAlign='center' className='blog-subtitle'>
                             Farmers are working more acres, <br />but profitability can still be elusive
                        </Header>
                        <p>Recently released federal statistics on agriculture in the United States held some surprising news for New Jersey: For the first time in four decades, farming is on the rise.</p>
                        <Grid className='section-grid' centered>
                            <Grid.Column mobile={16} tablet={16} computer={8}>
                                <Segment>
                                    <Chart chart='farm_land' margin={{
                                      top: 0, right: 0, left: 15, bottom: 5,
                                    }}/>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={8}>
                                <p>Agricultural acreage had declined in the Garden State by almost a third between 1978 and 2012, so when federal officials released the <a href="https://www.nass.usda.gov/AgCensus/" rel="noopener noreferrer" target="_blank">2017 Census of Agriculture</a> last April, the nearly 3 percent increase reported in acres farmed between 2012 and 2017 was unexpected. The growth of farming in the most densely populated state in the nation was even more shocking given that the number of acres used for agriculture dropped by close to 2 percent nationwide.</p>

                            </Grid.Column>
                        </Grid>
                        <p>“I was pleasantly surprised,” said Peter Furey, executive director of the New Jersey Farm Bureau. He attributed much of the “welcome” increase to hobby farmers, for whom farming is not the main source of income.</p>

                        <p>Thousands of farmers in New Jersey do make their livelihoods via the land. New Jersey farms generated close to <a href="https://www.nass.usda.gov/Publications/AgCensus/2017/Full_Report/Volume_1,_Chapter_1_State_Level/New_Jersey/st34_1_0001_0001.pdf" rel="noopener noreferrer" target="_blank">$1.1 billion</a> in cash receipts in 2017. The state is consistently among the nation’s top producers of peaches, cranberries, blueberries and eggplant.</p>

                        <p>But farming in such a highly developed and high-cost state can be difficult. Property taxes are higher than in most other states, even for farms.  Deer and, in some parts of the state, bears can gobble up crops. And soon a higher minimum wage is going to force farmers to choose between raising prices or making an even smaller profit.</p>

                        <p>Still, farmers are grateful that they have only these problems, given that the future of New Jersey farming looked grim a few decades ago. The state lost more than 300,000 acres of farms — roughly the size of Morris County — over the last 40 years. Housing subdivisions and mixed-use developments now occupy many former fields. The state’s aggressive efforts to maintain farming through special land assessment and preservation programs helped prevent the paving over of even more agricultural land.</p>


                        <p>“Both are consequential in stemming the loss of acres and farms that would otherwise occur in their absence due to financial stress,” Furey said. “Farmers are hanging in there, despite formidable challenges.”</p>

                        <p>Given the development pressures over the past 50 years,  it’s surprising this much agriculture has survived.</p>
                        <ImageElement src="sold.jpg" className='float-right'/>

                        <p>The post-World War II period was a time of prosperity. Couples that had put off marriage or starting a family in the years following the Depression or during the war began having children, spurring the baby boom that added some 76 million kids to the census rolls between 1946 and 1964. More Americans were buying cars and houses. Veterans could get affordable mortgages. Families looked for homes just outside the cities. Highway construction made getting to the suburbs easier.</p>

                        <p>Later waves of development from the early 1970s to the Great Recession of 2007  pushed suburban sprawl deeper into rural New Jersey, with McMansions sprouting on fields that used to grow corn or corral cows.</p>


                        <p>Doug Fisher, New Jersey’s agriculture secretary, explained that farmland was most often sacrificed for homes, office buildings and malls. </p>
                        <p>"There was development that was taking place at an incredibly fast pace,” he said. “Farmland is the easiest to build on, in many cases, for development. It has flat land."</p>
                        <p>It’s hard to imagine, but places like Parsippany in Morris County used to be farm country. Crisscrossed by Interstates 80, 280, and 287 and state Routes 46, 10, and 202, residential developments, office buildings, and strip malls now dominate the landscape. The Farrands, whose family had been farming in this area since the American Revolution, watched it change and finally left in 1962, moving about 20 miles west to the edge of Morris County.</p>

                        <p>Dan Farrand now owns the farm in Long Valley that his father bought when he left Parsippany and told about what farming was like there.</p>

                    <YouTubeVideo videoId='eKZTJThJuJI' title='dan family history'/>
                    <p>Data from the Census of Agriculture, tells the story of what happened. In 1950, 1.7 million acres of land were farmed. In 2017, including the small increase from 2012, farm acreage had dropped to just 734,000. Still, it could have been worse, had New Jersey legislators not put in place a number of programs to stem the losses.</p>
                 </Grid.Column>
             </Grid>
        </Section>
        <DynamicModule
            placeholder={<div>Loading...</div>}
            component={()=> import ('./Section_2')}
        />
    </React.Fragment>
  )
}

export default SectionOne;

import React from 'react';
import { Reveal, Image, Card } from 'semantic-ui-react'
import './Credits.css';

const CreditComponent = ({ data }) => {
    return (
        <Card.Group itemsPerRow={6} doubling className='credit-comp'>
            {data.map((c, i)=>(
                <Card
                    key={i}
                    className='credit-item'
                >
                    <Card.Content>
                         <Reveal animated='move down'>
                            <Reveal.Content visible>
                                <Image src={c.img} />
                            </Reveal.Content>
                            <Reveal.Content hidden>
                                <Image className='body-text'>
                                    <strong>{c.name}</strong> {c.bio}
                                </Image>
                            </Reveal.Content>
                         </Reveal>
                         <Card.Header className='credit-header'>
                            {c.name}
                         </Card.Header>
                    </Card.Content>
                </Card>
            ))}
        </Card.Group>
    )
}

export default CreditComponent;

import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ThemeProvider } from './ThemeContext';
import App from './App';

const Root = (props) => {
  return (
      <ParallaxProvider>
        <ThemeProvider>
            <App />
        </ThemeProvider>
      </ParallaxProvider>
  )
}

export default Root;

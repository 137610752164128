import React, { useContext } from 'react';
import { ThemeContext } from '../../ThemeContext';
import ReactBackgroundVideo from 'react-background-video-player';
import useVideoPlayer from './useVideoPlayer'
import './BackgroundVideo.css';


const BackgroundVideoPlayer = ({ src }) => {
    const { vid_src } = useContext(ThemeContext)
    const [ videoState, setState, setPlayer ] = useVideoPlayer()

  return (
    <div className='background-vid'>
        <div className='background-overlay'></div>
        <ReactBackgroundVideo
            ref={p => setPlayer(p) }
            containerWidth={videoState.windowWidth}
            containerHeight={videoState.windowHeight}
            src={vid_src('headers/' + src)}
            onPlay={()=>{ setState({isPlaying: true })}}
            onPause={()=>{ setState({isPlaying: false })}}
            startTime={0}
            autoPlay={true}
            volume={0}
        />
    </div>
  )
}


export default BackgroundVideoPlayer;

import React from 'react';
import posed from 'react-pose';
import { Link } from 'react-scroll'
import { gaEvent } from '../../lib/ga_utils'

const Item = posed.li({
    pressable: true,
    hoverable: true,
    init:{
        scale: 1,
        backgroundColor: '#aaa',
    },
    open: {
        y: 0,
        opactiy: 1
    },
    closed: {
        y: 20,
        opactiy: 0
    },
    press:{
        scale: 0.9
    },
    hover: {
        scale: 1.05,
        backgroundColor: '#aaa',
        boxShadow: '0px 5px 10px rgba(0,0,0,0.3)'
    }
})

const NavModalItem = ({ to, children, closeFn }) => {
    return (
        <Link to={to} smooth={true} duration={1500} offset={-60} delay={600} onClick={closeFn}>
            { children }
        </Link>
    )
}

const NavItem = ({ closeFn, id, title, inx}) => {
  return (
    <Item key={id} className='modal-item' onClick={()=>closeFn()}>
        <NavModalItem closeFn={()=>{
            gaEvent('Nav','Click Nav Item', title);
            closeFn();
        }} to={id}>
            {inx + 1}. { title }
        </NavModalItem>
    </Item>
  )
}

export default NavItem;

import React from 'react';
import { Segment, Image } from 'semantic-ui-react'
import './Credits.css';
import data from './credits_data'
import CreditComp from './CreditComponent';

import credit_title from '../../images/Credits_Title.png'
import thanks_title from '../../images/SpecialThanks_Title.png'


const Credits = () => {

    return (
        <Segment inverted className='credits' id='credits'>
            <Image src={credit_title} size='medium' centered  className='credits-header'/>
            <CreditComp data={data} />
            <Segment basic className='special-thanks'>
                <Image src={thanks_title}  size='medium' centered/>
                <p>Steve Carpenter, William Wyckoff, David Garrett, David Cruz and the entire NJTV News and NJ Spotlight staff.</p>
            </Segment>
            <Segment basic inverted className='special-thanks'>
                <p>
                    Special reporting projects like "Farmland Flourishes in the Garden State" <br />
                are made possible by the generous funders and members of NJTV News and NJ Spotlight.</p>
            </Segment>
        </Segment>
    )
}

export default Credits;

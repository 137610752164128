import React from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { Header } from 'semantic-ui-react'
import CustomTooltip  from '../CustomTooltip';

const colors = ["#82ca9d", "#8884d8", "#ffc658"]

const BarChartContainer = ({ height=300, data, header, source, keys=['value'], labels=[], margin }) => {
    return (
      <div>
      { !!header && <Header as='h3' textAlign='center'>{header}</Header>}
        <ResponsiveContainer height={height}>
          <BarChart
            className='chart'
            data={data}
            margin={margin}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name"/>
            <YAxis tickFormatter={tick=>`$${tick}`} />
            <Tooltip content={<CustomTooltip prefix='$'/>} />
            <Legend />
            {keys.map((k,i)=>(
              <Bar key={i} name={labels[i] ? labels[i] : keys[i]} type="monotone" dataKey={k} fill={colors[i]} />
            ))}

          </BarChart>
        </ResponsiveContainer>
        { !!source && <small>Source: <em>{source}</em></small>}
      </div>
    );

}

export default BarChartContainer;

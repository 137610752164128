import React from 'react';
import "./Section.css"
import { Grid, Button, Icon, Visibility } from 'semantic-ui-react'
import Banner from '../components/Banner'
import Scroll, { Element } from 'react-scroll'
import { gaEvent, gaPageView } from '../lib/ga_utils'

var scroll = Scroll.animateScroll;

const Section = ({ id, children, title, color, showBanner=true, ...rest }) => {

  return (
    <Grid.Row as={Element} name={id} id={id} className='section' {...rest}>
        <Visibility as='span' once onPassing={()=>{ gaPageView(title); }}/>
        {showBanner &&
            <Banner title={title} color={color} section_inx={id} />}
        <Grid.Column mobile={16} computer={14} largeScreen={13} className="section-content">
            {children}
            <Button basic floated='right' onClick={()=>{
                gaEvent('Scroll', `Scroll to Top`, title);
                scroll.scrollToTop();
            }}>
                <Icon name='arrow up' />
                scroll to top
            </Button>
        </Grid.Column>
    </Grid.Row>
  )
}

export default Section;

import React from 'react';
import { useMedia } from '../../lib/hooks';
import { ParallaxBanner } from 'react-scroll-parallax';
import { Header } from 'semantic-ui-react'
import './Banner.css';


const MediaBanner = ({ title, size, src_layer }) => {
  return (
    <ParallaxBanner
      className="banner"
      layers={[{ amount: 0.5, slowerScrollRate: false, ...src_layer }]}
      style={{
          height: size,
      }}
    >
      <div className="banner-content">
        <Header className='banner-text' >{ title }</Header>
      </div>
    </ParallaxBanner>
  )
}


const Banner = ({ title, size, section_inx }) => {
  const src = useMedia(section_inx);
  return <MediaBanner title={title} size={size} src_layer={src} />
}

Banner.defaultProps ={
    size: 300
}

export default Banner;

import React from 'react';
import { Segment, Header, Label } from 'semantic-ui-react'
import './CustomTooltip.css';

const CustomTooltip = ({ active, payload, label, prefix }) => {
  if (active) {
    if (!payload[0]) return null;
    return (
      <Segment inverted className='custom-tooltip'>
        <Header as='h4' inverted>{label}</Header>
        {payload.map((p, inx)=>(
          <div key={inx}>
            <Label circular style={{backgroundColor:p.color}} size='mini'/>{` `}
            {`${p.name} : ${prefix ? prefix : ''}${prefix === '$' ? p.value.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}) : p.value.toLocaleString()}`}
          </div>
        ))}
      </Segment>
    );
  }

  return null;
};

export default CustomTooltip;
